import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import { mobile, tablet } from "../responsive";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { publicRequest } from "../requestMethods";
import _ from "lodash";

const Container = styled.div``;

const Wrapper = styled.div``;

const BannerWrapper = styled.div`
${tablet({
    height: "50vh",

})}
`;

const Banner = styled.h1`
    padding: 20px;
    background-image: url("https://ik.imagekit.io/dnddecpho/haofa/Haofa_DesktopMainPage_WebBanner3_9Yrq5bZ9o.jpg?updatedAt=1684993266466");
    background-position: center;
    height: 60vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-transform: capitalize;
    font-size: 3rem;
    ${mobile({
        fontSize: "1.5rem",
        height: "50vh",
         backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_HomeBanner4_1__RShH6cD2Z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318705"})`,
            overflow: "auto",
    })}
    ${tablet({
        fontSize: "1.5rem",
        height: "100%",
         backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
        backgroundImage: `url(${"https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_HomeBanner4_1__RShH6cD2Z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318705"})`,
            overflow: "auto",
    })}
`;

const Input = styled.input`
    flex: 1;
    padding: 20px 18px;
    text-align: center;
    border: 1px black solid;
    font-family: FuturaPT-Demi;
    ${mobile({ width: "60vw" })}
    ${tablet({ width: "60vw" })}
`;

const SearchButton = styled.button`
    width: 25vw;
    border: 1px black solid;
    padding: 15px 20px;
    margin-bottom: 1rem;
    background-color: black;
    color: white;
    cursor: pointer;
    font-family: FuturaPT-Demi;
    &:hover {
        background: #ead61c;
        color: black;
    }
    ${mobile({ width: "60vw" })}
    ${tablet({ width: "60vw" })}
`;

const Item = styled.button`
    width: 100%;
    border: none;
    padding: 15px 20px;
    background-color: black;
    font-family: FuturaPT-Demi;
    color: white;
    ${mobile({ width: "40vw" })}
    ${tablet({ width: "40vw" })}
`;

const Content = styled.button`
    width: 100%;
    border: none;
    padding: 15px 20px;
    background-color: #ead61c;
    color: black;
    font-family: FuturaPT-Demi;
    text-transform: capitalize;
    ${mobile({ width: "40vw" })}
    ${tablet({ width: "40vw" })}
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const Info = styled.div`
    display: grid;
    grid-template-columns: auto;
    color: black;
    flex: 3;
    ${mobile({ margin: "0rem", display: "flex", flexDirection: "column" })}
    ${tablet({ margin: "0rem", display: "flex", flexDirection: "column" })}
`;

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    ${mobile({ flexDirection: "column", margin: "2rem 0", order: "2" })}
    ${tablet({ flexDirection: "column", margin: "2rem 0", order: "2" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", alignItems: "center" })}
    ${tablet({ flexDirection: "column", alignItems: "center" })}
`;

const ImgContainer = styled.div`
    ${mobile({ order: "1" })}
    ${tablet({ order: "1" })}
`;

const Image = styled.img`
    width: 100%;
    height: 40vh;
    object-fit: contain;
    cursor: pointer;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "30vh", margin: "0" })}
`;

const Details = styled.div`
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    ${mobile({ display: "flex", alignItems: "center" })}
    ${tablet({ display: "flex", alignItems: "center" })}
`;

const ProductName = styled.p`
    font-size: 2.2rem;
    text-transform: uppercase;
    color: black;
    text-align: center;
    font-family: "FuturaPT-Bold";
    margin-bottom: 4rem;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.7rem",
        padding: "0 1rem",
        marginBottom: "2rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.7rem",
        padding: "0 1rem",
        marginBottom: "2rem",
    })}
`;

const ProductId = styled.span`
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
`;

const TwoItemContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    ${mobile({
        display: "flex",
    })}
    ${tablet({
        display: "flex",
    })}
`;

const SearchContainer = styled.div`
    padding: 3rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid #e5e5e5;
    ${mobile({
        display: "flex",
        padding: "1rem 0 1rem 0",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "12px",
    })}
    ${tablet({
        display: "flex",
        padding: "1rem 0 1rem 0",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "12px",
    })}
`;

const InputContainer = styled.div`
    width: 25vw;
    height: 40px;
    background-color: white;
    display: flex;
    border: 1px solid lightgray;
    ${mobile({ width: "100%", height: "30px" })}
    ${tablet({ width: "100%", height: "30px" })}
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0.5rem 0rem;
    align-items: center;
`;

const WarrantyContainer = styled.div`
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const ImageA = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/Haofa/Haofa_WebsiteBanner_HomeBanner4_1__RShH6cD2Z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1669805318705");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 60vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
        height: "50vh",
    })}
    ${tablet({
        margin: "0rem",
        height: "50vh",
    })}
`;

const Words = styled.p`
    font-size: 1.8rem;
    text-transform: uppercase;
    color: black;
    text-align: center;
    font-family: "FuturaPT-Bold";
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.4rem",
        marginBottom: "1rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.4rem",
        marginBottom: "1rem",
    })}
`;

const Subtitle = styled.p`
    font-size: 1.2rem;
    color: black;
    text-align: center;
    font-family: "FuturaPT-Demi";
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        padding: "0 3rem",
        marginBottom: "2rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        padding: "0 3rem",
        marginBottom: "2rem",
    })}
`;

const InfoWrapper = styled.div`
    padding: 1.5rem 0rem;
`;

const Warranty = () => {
    const ref = useRef(null);
    const [order, setOrder] = useState(undefined);
    const [search, setSearch] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getOrder = async () => {
        try {
            const res = await publicRequest.get(
                "/warranty/findWarranty/" + search
            );
            setOrder(res.data);
            return res.data;
        } catch {
            alert(
                "Sorry, we couldn't find any matching result for your serial number. For assistance, you may contact support@haofa.my"
            );
        }
    };

    const handleSearch = async (e) => {
        const res = await getOrder();
        if (res !== undefined) {
            ref.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <Container>
            <Navbar />
            <Announcement />
            <Wrapper>
                <Banner></Banner>
                <TwoItemContainer>
                    <ImageA></ImageA>
                    <SearchContainer>
                        <Words>HAOFA WATCHES WARRANTY</Words>
                        <FlexContainer>
                            {" "}
                            <InputContainer>
                                <Input
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder={
                                        "Search Warranty by Serial Code"
                                    }
                                />
                            </InputContainer>
                        </FlexContainer>
                        <FlexContainer>
                            {" "}
                            <SearchButton onClick={handleSearch}>
                                Search
                            </SearchButton>
                        </FlexContainer>
                        <Subtitle>
                            This online platform is dedicated to HAOFA digital
                            watches warranties.{" "}
                        </Subtitle>
                        <Subtitle>
                            By authenticating your warranty on the platform, you can check the status & details
                            of your HAOFA watch warranty.{" "}
                        </Subtitle>
                    </SearchContainer>
                </TwoItemContainer>
                <Bottom ref={ref}>
                    <Info>
                        {order !== undefined ? (
                            <InfoWrapper>
                                <ProductName>
                                    HAOFA {order.model} {order.title}
                                </ProductName>
                                <WarrantyContainer>
                                    <ImgContainer>
                                        <Image src={order.img} />
                                    </ImgContainer>

                                    <Product>
                                        <ProductDetail>
                                            <Details>
                                                <ProductId>
                                                    <Item>Serial Number</Item>
                                                    <Content>
                                                        {order.serialNumber}{" "}
                                                    </Content>
                                                </ProductId>
                                                <ProductId>
                                                    <Item>Model / Color </Item>
                                                    <Content>
                                                        {order.code}{" "}
                                                    </Content>
                                                </ProductId>

                                                <ProductId>
                                                    <Item>Outlet</Item>
                                                    <Content>
                                                        {order.outlet}{" "}
                                                    </Content>
                                                </ProductId>

                                                <ProductId>
                                                    <Item>Country</Item>
                                                    <Content>
                                                        {order.country}{" "}
                                                    </Content>
                                                </ProductId>
                                                <ProductId>
                                                    <Item>Warranty Status</Item>
                                                    <Content>
                                                        {" "}
                                                        {_.capitalize(
                                                            order.status
                                                        )}{" "}
                                                    </Content>
                                                </ProductId>
                                                <ProductId>
                                                    <Item>Purchased Date</Item>
                                                    <Content>
                                                        {order.startDate
                                                            ? moment(
                                                                  order.startDate
                                                              ).format(
                                                                  "DD MMM YYYY"
                                                              )
                                                            : "-"}{" "}
                                                    </Content>
                                                </ProductId>
                                                <ProductId>
                                                    <Item>Valid Until</Item>
                                                    <Content>
                                                        {order.startDate
                                                            ? moment(
                                                                  order.startDate
                                                              )
                                                                  .add(
                                                                      3,
                                                                      "years"
                                                                  )
                                                                  .format(
                                                                      "DD MMM YYYY"
                                                                  )
                                                            : "-"}
                                                    </Content>
                                                </ProductId>
                                            </Details>
                                        </ProductDetail>
                                    </Product>
                                </WarrantyContainer>
                            </InfoWrapper>
                        ) : (
                            <></>
                        )}
                    </Info>
                </Bottom>
            </Wrapper>
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default Warranty;
