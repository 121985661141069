import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
        discount: 0,
        affiliateCode: undefined,
    },
    reducers: {
        addProduct: (state, action) => {
            let productDiscount;

            const product = action.payload;

            switch (product.model) {
                case "2323":
                case "1909L":
                case "1909L-1":
                case "2203":
                case "1901-1":
                    productDiscount = 580;
                    break;
                case "2290":
                case "2291":
                    productDiscount = 880;
                    break;
                case "2000":
                    productDiscount = 900;
                    break;
                case "1986":
                    productDiscount = 380;
                    break;
                case "1978":
                    productDiscount = 480;
                    break;
                default:
                    productDiscount = 5; // Set default value if no match
                    break;
            }


            state.quantity += 1;
            state.products.push({
                _id: product._id,
                title: product.title,
                color: product.activeColor,
                model: product.model,
                price: product.myprice,
                movementModel: product.movementModel,
                powerReserve: product.powerReserve,
                mirror: product.mirror,
                quantity: product.quantity,
            });
        },
        addQuantity: (state, action) => {
            const existingProductIndex = state.products.findIndex(
                (item) =>
                    `${item.title}-${item.color.name}` ===
                    `${action.payload.title}-${action.payload.activeColor.name}`
            );
            state.products[existingProductIndex].quantity =
                state.products[existingProductIndex].quantity +
                action.payload.quantity;
            state.total =
                state.total + action.payload.price * action.payload.quantity;
            if (state.products[existingProductIndex].quantity <= 0) {
                state.products.splice(existingProductIndex, 1);
                state.quantity -= 1;
            }
        },
        clearCart: (state) => {
            state.products = [];
            state.quantity = 0;
            state.total = 0;
        },
        applyDiscount: (state, action) => {
            state.discount = action.payload;
        },
        applyAffiliateCode: (state, action) => {
            state.affiliateCode = action.payload;
        },
        clearDiscount: (state) => {
            state.discount = 0;
        },
    },
});

export const {
    addProduct,
    addQuantity,
    clearCart,
    applyDiscount,
    applyAffiliateCode,
    clearDiscount,
} = cartSlice.actions;
export default cartSlice.reducer;
